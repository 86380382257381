import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
import { Link } from "gatsby";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomButton from "components/CustomButtons/CustomButton.jsx";
import ButtonWWD from "components/CustomButtons/ButtonWWD.jsx";
import ButtonPartners from "components/CustomButtons/ButtonPartners.jsx";
import ButtonContact from "components/CustomButtons/ButtonContact.jsx";
import DownloadButton from "components/CustomButtons/DownloadButton.jsx";
import SpecButton from "components/CustomButtons/SpecButton.jsx";

import Parallax from "components/Parallax/Parallax.jsx";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import SectionBasics from "./Sections/SectionBasics.jsx";
import SectionNavbars from "./Sections/SectionNavbars.jsx";
import SectionTabs from "./Sections/SectionTabs.jsx";
import SectionPills from "./Sections/SectionPills.jsx";
import SectionNotifications from "./Sections/SectionNotifications.jsx";
import SectionTypography from "./Sections/SectionTypography.jsx";
import SectionJavascript from "./Sections/SectionJavascript.jsx";
import SectionCarousel from "./Sections/SectionCarousel.jsx";
import SectionCompletedExamples from "./Sections/SectionCompletedExamples.jsx";
import SectionLogin from "./Sections/SectionLogin.jsx";
import SectionExamples from "./Sections/SectionExamples.jsx";
import SectionDownload from "./Sections/SectionDownload.jsx";
import ProductSection from "./Sections/ProductSection.jsx";

import portrait from "../../assets/img/portrait2.png";
import specshome from "../../assets/img/specshome.png";
import footimg from "../../assets/img/footimg.png";
import image from "../../assets/img/footimg.png";
import dubaiimage from "../../assets/img/Dubai.jpg";
import contactheader from "../../assets/img/ContactHeader.jpg";
import partnersheader from "../../assets/img/OurPartnersHeader.jpg";
import esginsert from "../../assets/img/ESGInsert.jpg";
import colliery from "../../assets/img/colliery.png";
import wwd from "../../assets/img/wwd.jpg";

import esgStyle from "assets/jss/material-kit-react/views/esgPage.jsx";

class ESGPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          brand="Material Kit React"
          rightLinks={<HeaderLinks />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
        <Parallax image={require("assets/img/wwd.jpg")}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem>
                <div className={classes.brand}>
                <div className={classes.intro}>
                  ESG
                  </div>
                  
                  <div className={classes.subtitle}>
                  Sustainable, socially positive and responsibly governed.

                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>

        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.mainwrap}>
          {/* <ProductSection /> */}
          <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
          <div className={classes.preheading}>
          A sustainable future
                  </div>
                 
                <div className={classes.parawrap}>
                <p className={classes.paratext}>
                Our aim is to create long-term development solutions and investment returns in a responsible manner. To achieve that, we continually strive to increase the sustainability of our projects, believing as we do that environmentally positive development is not just an urgent ecological necessity, it is also key to creating long-term opportunities now and in the future.
                <br/>
                <br/>
                Our commitment to that future goes beyond sustainably sourced material use in construction and developing efficient, smart buildings – although we ensure that we do both. We also focus on projects which provide amenities for local communities and work with accredited partners from those same communities to reduce travel impact and ensure investment is kept within the area to help it thrive.
                </p>
                </div>
                
            </GridItem>
            </GridContainer>

            </div>  
          </div>

          <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.mainwrapfoot}>
          {/* <ProductSection /> */}

          <div className={classes.bgimg}       style={{
            backgroundImage: "url(" + esginsert + ")",
            backgroundSize: "cover",
            backgroundPosition: "center center"
          }}>
                    <div className={classes.container}>
            <GridContainer>
              <GridItem>
              <div className={classes.brand}>
                  
                  <div className={classes.subtitle}>
                  “Environmentally positive development is not just an urgent ecological necessity, it is also key to creating long-term opportunities now and in the future.”

                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
          </div>

            </div>  
          </div>

          <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.mainwrap}>
          {/* <ProductSection /> */}
          <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
          <div className={classes.preheading}>
          Culture and ethics
                  </div>
                 
                <div className={classes.parawrap}>
                <p className={classes.paratext}>
                We invest in our own people and create work spaces where openness, respect and safety is paramount, be that on site or in our day-to-day interactions with suppliers. We promote integrity and professionalism throughout the company and ensure every person feels responsible for the performance and reputation of VCD.
                <br/>
                <br/>
                We will not enter into or maintain relationships with individuals or entities who do not align with our code of ethics. Our client take-on processes include a mandatory review to ensure they are not involved in any activities which are socially or environmentally detrimental.
                </p>
                </div>
                
            </GridItem>
            </GridContainer>

            </div>  
          </div>

          <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.mainwrapfoot}>
          {/* <ProductSection /> */}

          <div className={classes.bgimg}       style={{
            backgroundImage: "url(" + contactheader + ")",
            backgroundSize: "cover",
            backgroundPosition: "center center"
          }}>
                    <div className={classes.container}>
            <GridContainer>
              <GridItem>
                <div className={classes.brandfooter}>
                <div className={classes.introcontact}>
                  Contact us
                  </div>
                <ButtonContact/>
                </div>
              </GridItem>
            </GridContainer>
          </div>
          </div>

            </div>  
          </div>

        <div className={classNames(classes.mainlinks, classes.mainRaisedlinks)}>

            <div className={classes.mainwraplinks}>
            <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
          <div className={classes.imagewrap}>
          <img alt="..." src={partnersheader} className={classes.homeimage} />
          </div>
          <div className={classes.parawraplinks}>
          <ButtonPartners/>
                <p className={classes.paratextlinks}>
                Find out more about our team of experienced real estate professionals.
                </p>
                
              </div>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
          <div className={classes.imagewrap}>
          <img alt="..." src={wwd} className={classes.homeimage} />
          </div>
          <div className={classes.parawraplinks}>
          <ButtonWWD/>
                <p className={classes.paratextlinks}>
                Asset management, development, build, investment and equity services.
                </p>
            
              </div>
            </GridItem>
            
            </GridContainer>

            </div>   
        </div>

        <Footer />
      </div>
    );
  }
}

export default withStyles(esgStyle)(ESGPage);